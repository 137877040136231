function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import HideCard from '@components/HideCard';
import SidebarPlayer from '@components/player/sidebarPlayer';
import LiveDotVideoCard from '@components/videoCard/LiveDotVideoCard';
import { PlayerSpinner } from '@components/VideoPlayer/Components';
import { VideoComponentInterface } from '@customTypes/index';
import { useHover } from '@hooks/useHover';
import { useAppStore } from '@stores/appStore';
import { useStreamStore } from '@stores/streamStore';
import { isBannerVideo } from '@utils/feedData';
import Link from 'next/link';
import { LegacyRef, useMemo } from 'react';
import AspectRatio from '../aspectRatio';
import VideoThumbnail from './VideoThumbnail';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const setUnicornSourceTrackingInfo = useStreamStore.getState().setUnicornSourceTrackingInfo;
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1mtc5n4",
  styles: "display:flex;height:100%;width:100%;flex-direction:row;padding:0px;transition-property:all;transition-timing-function:linear;transition-duration:300ms"
} : {
  name: "sfyzd5-SideVideoComponent",
  styles: "display:flex;height:100%;width:100%;flex-direction:row;padding:0px;transition-property:all;transition-timing-function:linear;transition-duration:300ms;label:SideVideoComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "j64fqp",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%"
} : {
  name: "9smue-SideVideoComponent",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%;label:SideVideoComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "vm0ud9",
  styles: "position:absolute;right:0.25rem;bottom:0.5rem;z-index:1;padding-left:0.5rem;padding-right:0.5rem"
} : {
  name: "admtk7-SideVideoComponent",
  styles: "position:absolute;right:0.25rem;bottom:0.5rem;z-index:1;padding-left:0.5rem;padding-right:0.5rem;label:SideVideoComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "17ntetd",
  styles: "float:left;clear:both;margin-top:0.25rem;border-bottom-right-radius:0.25rem;border-bottom-left-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(56 56 56 / var(--tw-bg-opacity));padding-left:0.875rem;padding-right:0.875rem;padding-top:0.125rem;padding-bottom:0.125rem;font-size:10px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "v4juan-SideVideoComponent",
  styles: "float:left;clear:both;margin-top:0.25rem;border-bottom-right-radius:0.25rem;border-bottom-left-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(56 56 56 / var(--tw-bg-opacity));padding-left:0.875rem;padding-right:0.875rem;padding-top:0.125rem;padding-bottom:0.125rem;font-size:10px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:SideVideoComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const SideVideoComponent = ({
  video,
  source_component_data
}: {
  video: {
    component: VideoComponentInterface;
  };
  source_component_data: {
    [id: string]: string | number;
  };
}) => {
  const {
    sourceTrackingInfo,
    setSourceTrackingInfo
  } = useAppStore(state => state);
  const [hoverRef, isHovering] = useHover();
  const isBanner = isBannerVideo(video);
  const videoData = video.component.data;
  const linkTarget = videoData.thumbnail?.open_new_tab ? '_blank' : '_self';
  let linkUrl = `/stream/${video.component.data?.stream_uid?.data}`;
  if (isBanner) {
    const url = `${video.component.data.thumbnail?.click_action?.deep_link}`;
    linkUrl = url.split('web_url=')[1];
    if (!linkUrl) {
      linkUrl = url;
    }
  }
  const VideoThumbnailComponent = useMemo(() => _jsx(VideoThumbnail, {
    video: video,
    hideTitle: true
  }), [video]);
  const handleClick = () => {
    setSourceTrackingInfo({
      ...sourceTrackingInfo,
      ...source_component_data
    });
    setUnicornSourceTrackingInfo({
      ...sourceTrackingInfo,
      ...source_component_data
    });
  };
  return _jsx("div", {
    css: _ref,
    ref: (hoverRef as LegacyRef<HTMLDivElement>),
    children: _jsx(AspectRatio, {
      ratio: 16 / 9,
      children: _jsx(Link, {
        href: linkUrl,
        as: linkUrl,
        prefetch: false,
        passHref: true,
        legacyBehavior: true,
        children: _jsxs("a", {
          onClick: handleClick,
          role: "presentation",
          target: linkTarget,
          children: [isBanner ? VideoThumbnailComponent : isHovering && video?.component?.data?.playback_url?.playback && !video?.component?.data?.has_mature_content ? _jsx(SidebarPlayer, {
            src: video.component.data.playback_url.playback,
            loader: _jsx(PlayerSpinner, {
              size: 42
            }),
            fallbackUI: params => {
              if (!params?.error) {
                return _jsx(_Fragment, {});
              }
              return _jsxs("div", {
                css: _ref2,
                children: [VideoThumbnailComponent, _jsx(HideCard, {
                  duration: 500,
                  children: _jsx("div", {
                    css: _ref3,
                    children: _jsx("div", {
                      css: _ref4,
                      children: "Failed to load video"
                    })
                  })
                })]
              });
            }
          }) : _jsx(VideoThumbnail, {
            video: video
          }), !isBanner && !isHovering && _jsx(LiveDotVideoCard, {
            isLive: video.component?.data?.is_live,
            views: video?.component?.data?.view_count?.data.split(' ')[0] || '0'
          })]
        })
      })
    })
  });
};
export default SideVideoComponent;